import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import Navigation from '../../pages/Navigation/navigation.js';
import Login from '../../pages/Login/login';
import { useSelector } from 'react-redux';
import ResetPassword from '../../pages/ResetPassword/resetPassword';

const Layout = ({ children }) => {
    const token = useSelector((state) => state.token);
    var userInfos = useSelector((state) => state.userInfos);
    const [roles,setRoles] = useState([]);
    const [rolesOld,setRolesOld] = useState([]);
    
    useEffect(() => {
        if(roles.length == 0 && rolesOld.length == 0){
            console.log("set roles",userInfos)
            setRoles(userInfos && userInfos.user_role && userInfos.user_role.pages ? userInfos.user_role.pages : []);
            setRolesOld(userInfos && userInfos.user_role && userInfos.user_role.pages ? userInfos.user_role.pages : []);
        }
    },[userInfos])

    if ((token == null || token == '') && window.location.pathname.split("/").length == 2) {
        return <Login />
    }else if((token == null || token == '') && window.location.pathname.split("/").length > 0 && window.location.pathname.split("/")[1] == "sifre-guncelleme"){
        return <ResetPassword/>
    }

    return (
        <React.Fragment>
            <Header />
            <div className="navigationWrapper">
                <Navigation roles={roles} rolesOld={rolesOld} setRoles={setRoles}/>
                <main>{children}</main>
            </div>
        </React.Fragment>
    );
};
export default Layout;
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../../config";
import Breadcrumb from "../../Partials/Breadcrumb/breadcrumb";

const Home = () => {
  let pages = [{ name: "Anasayfa", link: "/" }];
  const [values,setValues] = useState();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);

  useEffect(() => {
    async function fetchData() {
        const response = await fetch(apiUrl + "/api/dashboard",
        {
            headers: {
                authorization: `Bearer ${token}`,
            }
        });
        const data = await response.json()
        console.log({data})
        setValues(data);       
    }
    fetchData();
},[]);

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="content-wrapper">
          <div className="row page">
            <Breadcrumb pageTitle={"Anasayfa"} pages={pages} />
                <div className="col-lg-3">
                  <div className="small-box bg-green">
                    <div className="inner">
                      <h3>{values ? values.confirmed_user_count : null}</h3>

                      <p>Onaylanmış Kullanıcı Sayısı</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-person-add"></i>
                    </div>
                    <a href="#" className="small-box-footer" onClick={() =>  navigate('/kullanicilar')}>Daha fazlası <i className="fa fa-arrow-circle-right"></i></a>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="small-box bg-aqua">
                    <div className="inner">
                      <h3>{values ? values.unconfirmed_user_count : null}</h3>

                      <p>Onaylanmamış Kullanıcı Sayısı</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-person-add"></i>
                    </div>
                    <a href="#" className="small-box-footer" onClick={() =>  navigate('/kullanicilar')}>Daha fazlası <i className="fa fa-arrow-circle-right"></i></a>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="small-box bg-yellow">
                    <div className="inner">
                      <h3>{values ? values.confirmed_admin_count : null}</h3>

                      <p>Onaylanmış Admin Hesabı</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-pie-graph"></i>
                    </div>
                    <a href="#" className="small-box-footer" onClick={() =>  navigate('/operatorler')}>Daha fazlası <i className="fa fa-arrow-circle-right"></i></a>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="small-box bg-red">
                    <div className="inner">
                      <h3>{values ? values.unconfirmed_admin_count : null}</h3>

                      <p>Onaylanmamış Admin Hesabı</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-pie-graph"></i>
                    </div>
                    <a href="#" className="small-box-footer" onClick={() =>  navigate('/operatorler')}>Daha fazlası <i className="fa fa-arrow-circle-right"></i></a>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {useNavigate } from 'react-router-dom';
import FsLightbox from "fslightbox-react";
import { apiUrl } from "../../../config";
import Alert from "../../Partials/Modals/Alert/alert";

const AcceptDetail = () => {
    const [acceptInfo,setAcceptInfo] = useState();
    const [alert,setAlert] = useState();
    const [errorAlert,setErrorAlert] = useState();
    const [toggler, setToggler] = useState(false);
    const [toggler2, setToggler2] = useState(false);
    const [toggler3, setToggler3] = useState(false);
    const [toggler4, setToggler4] = useState(false);
    const [toggler5, setToggler5] = useState(false);
    const [toggler6, setToggler6] = useState(false);
    const token = useSelector((state) => state.token);
    const navigate = useNavigate();
    const paths = window.location.pathname.split("/");

    useEffect(() => {
        if(paths && paths.length > 2){
            async function fetchData() {
                const data = await fetch(apiUrl + '/api/vehicle-details/' + paths[2] + "?populate=images,vehicle_registration_certificate,user.licence_photo_back,user.licence_photo_front,user.identify_photo_back,user.identify_photo_front,user.profile.picture,vehicle_brand,vehicle_model,status&filters[status][id]=2", {
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${token}`,
                    }
                });
                setAcceptInfo(await data.json());
            }
            fetchData();
        }

    },[alert,errorAlert])

    const cancel = () => {
        navigate('/arac-onaylari');
    }

    const acceptLicenceOrIdentify = async (type) => {
        let body = {};
        if(type == "identify"){
            body = { licence_approvement: true};
        }else{
            body = { identity_approvement: true};
        }
        const data = await fetch(apiUrl + '/api/users/' + acceptInfo?.data?.attributes.user?.data?.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        });
        let result = await data.json();
        if(result.error && result.error.status == 400){
            setErrorAlert(type);
        }else{
            setAlert(type);
        }
    }
    return (<>
        {alert ? <Alert title={alert == "identify" ? "Kimlik Onaylama" : "Ehliyet Onaylama"} description={alert == "identify" ?  "Kimlik Onaylaması Yapıldı." : "Ehliyet Onaylaması Yapıldı."} state={alert} setState={setAlert}/> : null}
        {errorAlert ? <Alert title={errorAlert == "identify" ? "Kimlik Onaylama" : "Ehliyet Onaylama"} description={errorAlert == "identify" ?  "Kimlik Onaylaması Yapılamadı." : "Ehliyet Onaylaması Yapılamadı."} state={errorAlert} setState={setErrorAlert}/> : null}
        <div className="row">
            <div className="col-lg-12">
                <div className="content-wrapper">
                    <div className="row page">
                        <div className="col-lg-12 pageTitle">
                            <h1>Onay Bilgisi Görüntüleme</h1>
                        </div>
                        <div className="col-lg-12 acceptPage">
                            <div className="box box-info">
                                <form className="form-horizontal">
                                    <div className="row">
                                        <div className="col-lg-2">
                                            <img src={apiUrl + acceptInfo?.data?.attributes.user?.data?.attributes?.profile?.data?.attributes?.picture?.data?.attributes?.url} disabled style={{width: "100%"}}/>
                                        </div>
                                        <div className="col-lg-10">
                                            <label htmlFor="inputName" className="col-sm-10 control-label">Kullanıcı Adı</label>

                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputUserName" defaultValue={acceptInfo?.data?.attributes?.user?.data?.attributes?.username} disabled/>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="vehiclePhotos-tab" data-bs-toggle="tab" data-bs-target="#vehiclePhotos" type="button" role="tab" aria-controls="vehiclePhotos" aria-selected="true">Araç Fotoğrafları</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="licence-tab" data-bs-toggle="tab" data-bs-target="#licence" type="button" role="tab" aria-controls="licence" aria-selected="false">Ehliyet Doğrulama</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="identify-tab" data-bs-toggle="tab" data-bs-target="#identify" type="button" role="tab" aria-controls="identify" aria-selected="false">Kimlik Doğrulama</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="registrationCertificate-tab" data-bs-toggle="tab" data-bs-target="#registrationCertificate" type="button" role="tab" aria-controls="registrationCertificate" aria-selected="false">Ruhsat Kontrol</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="vehiclePhotos" role="tabpanel" aria-labelledby="vehiclePhotos-tab">
                                            <div className="row">
                                                <label className="col-sm-12 control-label">Araç Fotoğrafları</label>
                                                {acceptInfo && acceptInfo.data?.attributes?.images?.data?.length > 0 ? 
                                                    acceptInfo.data.attributes.images.data.map((item,index) => 
                                                    <div className="col-lg-4" key={index}>
                                                        <img src={apiUrl + item.attributes.url} style={{width: "100%",height: "200px"}} onClick={() => setToggler(!toggler)}/>
                                                    </div>
                                                    ): null}
                                                    <FsLightbox
                                                        toggler={toggler}
                                                        sources={acceptInfo?.data?.attributes?.images?.data.map((item) =>  {return apiUrl + item.attributes.url})}
                                                    />
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="registrationCertificate" role="tabpanel" aria-labelledby="registrationCertificate-tab">
                                            <div className="row">
                                                <label className="col-sm-12 control-label">Araç Ruhsat Fotoğrafları</label>
                                                {acceptInfo && acceptInfo.data?.attributes?.vehicle_registration_certificate?.data?.length > 0 ? 
                                                    acceptInfo.data.attributes.vehicle_registration_certificate.data.map((item,index) => 
                                                    <div className="col-lg-4" key={index}>
                                                        <img src={apiUrl + item.attributes.url} disabled style={{width: "100%",height: "200px"}} onClick={() => setToggler2(!toggler2)}/>
                                                    </div>): null}
                                                <FsLightbox
                                                    toggler={toggler2}
                                                    sources={acceptInfo?.data?.attributes?.vehicle_registration_certificate?.data?.map((item) =>  {return apiUrl + item.attributes.url})}
                                                />
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="licence" role="tabpanel" aria-labelledby="licence-tab">
                                            <div className="row">
                                                <label className="col-sm-12 control-label">Ehliyet Fotoğrafları</label>
                                                {acceptInfo && acceptInfo?.data?.attributes.user?.data?.attributes?.licence_photo_back?.data ? 
                                                    <div className="col-lg-4">
                                                        <img src={apiUrl + acceptInfo?.data?.attributes.user?.data?.attributes?.licence_photo_back?.data?.attributes?.url} disabled style={{width: "100%",height: "200px"}} onClick={() => setToggler3(!toggler3)}/>
                                                    </div>: null}
                                                <FsLightbox
                                                    toggler={toggler3}
                                                    sources={[apiUrl + acceptInfo?.data?.attributes.user?.data?.attributes?.licence_photo_back?.data?.attributes.url]}
                                                />
                                                {acceptInfo && acceptInfo?.data?.attributes.user?.data?.attributes?.licence_photo_front?.data ? 
                                                    <div className="col-lg-4">
                                                        <img src={apiUrl + acceptInfo?.data?.attributes.user?.data?.attributes?.licence_photo_front?.data?.attributes?.url} disabled style={{width: "100%",height: "200px"}} onClick={() => setToggler4(!toggler4)}/>
                                                    </div>: null}
                                                <FsLightbox
                                                    toggler={toggler4}
                                                    sources={[apiUrl + acceptInfo?.data?.attributes.user?.data?.attributes?.licence_photo_front?.data?.attributes.url]}
                                                />
                                                {!acceptInfo?.data?.attributes.user?.data?.attributes?.licence_approvement ? 
                                                <div className="col-sm-12">
                                                    <button className="btn btn-primary acceptButtons" onClick={() => acceptLicenceOrIdentify("licence")}>Onayla</button>
                                                </div> : null}
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="identify" role="tabpanel" aria-labelledby="identify-tab">
                                            <div className="row">
                                                <label className="col-sm-12 control-label">Kimlik Fotoğrafları</label>
                                                {acceptInfo && acceptInfo?.data?.attributes.user?.data?.attributes?.identify_photo_back?.data ? 
                                                    <div className="col-lg-4">
                                                        <img src={apiUrl + acceptInfo?.data?.attributes.user?.data?.attributes?.identify_photo_back.data.attributes.url} disabled style={{width: "100%",height: "200px"}} onClick={() => setToggler5(!toggler5)}/>
                                                    </div>: null}
                                                <FsLightbox
                                                    toggler={toggler5}
                                                    sources={[apiUrl + acceptInfo?.data?.attributes.user?.data?.attributes?.identify_photo_back?.data?.attributes?.url]}
                                                />
                                                {acceptInfo && acceptInfo?.data?.attributes.user?.data?.attributes?.identify_photo_front?.data ? 
                                                    <div className="col-lg-4" >
                                                        <img src={apiUrl + acceptInfo?.data?.attributes.user?.data?.attributes?.identify_photo_front?.data?.attributes.url} disabled style={{width: "100%",height: "200px"}} onClick={() => setToggler6(!toggler6)}/>
                                                    </div>: null}
                                                <FsLightbox
                                                    toggler={toggler6}
                                                    sources={[apiUrl + acceptInfo?.data?.attributes.user?.data?.attributes?.identify_photo_front?.data?.attributes?.url]}
                                                />
                                                {!acceptInfo?.data?.attributes.user?.data?.attributes?.identity_approvement ? 
                                                <div className="col-sm-12">
                                                    <button className="btn btn-primary acceptButtons" onClick={() => acceptLicenceOrIdentify("identify")}>Onayla</button>
                                                </div> : null}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-4">
                                            <label htmlFor="inputBrand" className="col-sm-10 control-label" >Marka</label>

                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputBrand" defaultValue={acceptInfo?.data?.attributes?.vehicle_brand?.data?.attributes?.name} disabled/>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="inputModel" className="col-sm-10 control-label">Model</label>

                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputModel"  defaultValue={acceptInfo?.data?.attributes?.vehicle_model?.data?.attributes?.name} disabled/>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="inputModelYear" className="col-sm-10 control-label">Model Yılı</label>

                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputModelYear" defaultValue={acceptInfo?.data?.attributes?.model_year} disabled/>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="inputPlate" className="col-sm-10 control-label">Plaka</label>

                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputPlate" defaultValue={ acceptInfo?.data?.attributes?.plate_number} disabled/>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="inputGear" className="col-sm-10 control-label">Vites Tipi</label>

                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputGear" defaultValue={acceptInfo?.data?.attributes?.gear_type} disabled/>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="inputFuel" className="col-sm-10 control-label">Yakıt Tipi</label>

                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputFuel" defaultValue={acceptInfo?.data?.attributes?.fuel_type} disabled/>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="inputDailyPrice" className="col-sm-10 control-label">Günlük Ücret</label>

                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputDailyPrice" defaultValue={acceptInfo?.data.attributes?.daily_price} disabled/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3"><button type="button" className="btn btn-default declineButton" onClick={() => cancel()}>Geri Dön</button></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default AcceptDetail;